import { ComponentType } from '@angular/cdk/portal';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';

export type PossibleDialogClasses
  = 'app-dialog'
  | 'app-dialog-standard'
  | 'app-dialog-small'
  | 'app-dialog-fullscreen'
  | 'dialog-w-auto'
  | 'dialog-h-auto'
  | 'dialog-w-full'
  | 'dialog-h-full'
  | 'custom';


MatDialog.prototype.openTyped = MatDialog.prototype.open;

type MatDialogConfigTyped<D = any> = MatDialogConfig<D> & {
  panelClass: PossibleDialogClasses | PossibleDialogClasses[];
};

declare module '@angular/material/dialog/dialog' {
  interface MatDialog {
    openTyped<T, D, R>(component: ComponentType<T>, config?: MatDialogConfigTyped<D>): MatDialogRef<T, R>;
  }
}
