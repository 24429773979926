import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarConfig } from './snackbar.constants';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class ShellSnackbarComponent {

  public icon = this.data.status === 'success' ? 'done' : this.data.status === 'error' ? 'clear' : 'help_outline';
  public message = this.data.message;
  public redIcon = this.data.status !== 'success';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    readonly data: SnackbarConfig,
    readonly router: Router,
  ) {
  }

  navTo() {
    if (this.data.navTo) {
      this.router.navigateByUrl(this.data.navTo);
    }
  }
}

