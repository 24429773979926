export { };

Array.prototype.distinct = function <T, P>(getProp?: (el: T) => P) {
  const array: Array<T> = this;
  const result = new Array<T>();
  for (const el of array) {
    if (getProp) {
      if (!result.map(r => getProp(r)).includes(getProp(el))) {
        result.push(el);
      }
    } else {
      if (!result.includes(el)) {
        result.push(el);
      }
    }
  }
  return result;
};

Array.prototype.peek = function <T>(fn: (el: T, index: number) => any) {
  const array: Array<T> = this;
  array.forEach((e, i) => fn(e, i));
  return this;
};

Array.prototype.mapToObject = function <T, K, V>(getKey: (el: T, index: number) => K, getVal: (el: T, index: number) => V) {
  const array: Array<T> = this;
  const result = {} as { [key: string]: V };
  array.forEach((element, index) => result[getKey(element, index).toString()] = getVal(element, index));
  return result;
};

Array.prototype.groupBy = function <T, P extends string>(fn: (el: T) => P) {
  const array: Array<T> = this;
  const result: T[][] = [];
  array.forEach(e => {
    const key = fn(e);
    const arr = result.find(r => r.some(rr => fn(rr) === key));
    if (arr) {
      arr.push(e);
    } else {
      result.push([e]);
    }
  });
  return result;
};

declare global {
  interface Array<T> {
    /**
     * Removes duplicate elements from the array.
     *
     * @example
     * // If no function is supplied, elements will be compared directly.
     * // This overload is useful for comparing arrays of primitives or strings, and not arrays of objects
     * array.distinct();
     * @example
     * // If a function is supplied, the property returned by that function will be used to compare elements
     * array.distinct(e => e.id);
     */
    distinct: <P>(getProp?: (el: T) => P) => T[];
    /**
     * Can be used to perform some side-effect using each array element, for example, this function can be used to log each element value.
     *
     * @example
     * array.peek(console.log);
     */
    peek: (fn: (element: T, index: number) => any) => T[];
    /**
     * Converts the array to an object.
     *
     * @example
     * const mapOfIdsToNames = array.mapToObject(e => e.id, e => e.name);
     */
    mapToObject: <K, V>(getKey: (element: T, index: number) => K, getVal: (element: T, index: number) => V) => { [key: string]: V };
    /**
     * Group the array by one of its properties
     */
    groupBy: <P extends string>(getProp?: (el: T) => P) => T[][];
  }
}
