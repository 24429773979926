import { ValueOf } from 'shared/consts';

export { };

Object.keysTyped = <T>(o: T) => Object.keys(o) as Array<keyof T>;
Object.valuesTyped = <T>(o: T) => Object.values(o) as Array<ValueOf<T>>;
Object.entriesTyped = <T>(o: T) => Object.entries(o) as Entries<T>;
Object.deepFreeze = <T>(o: T) => deepFreeze<T>(o);

export const deepFreeze = <T>(o: T): T => {
  Object.freeze(o);
  if (o === null || 0 === undefined) { return o; }
  (Object.getOwnPropertyNames(o) as Array<keyof T>).forEach(prop => {
    if (o.hasOwnProperty(prop)
      && o[prop] !== null
      && (typeof (o[prop]) === 'object' || typeof (o[prop]) === 'function')
      && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop]);
    }
  });
  return o;
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

declare global {
  interface Object {
    keysTyped: <T>(o: T) => Array<keyof T>;
    valuesTyped: <T>(o: T) => Array<ValueOf<T>>;
    entriesTyped: <T>(o: T) => Entries<T>;
    deepFreeze: <T>(o: T) => T;
  }
}
