import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ShellSnackbarComponent } from 'shared/modules/shell-snackbar/snackbar.component';
import { SnackbarConfig } from 'shared/modules/shell-snackbar/snackbar.constants';


MatSnackBar.prototype.show = function(config) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(ShellSnackbarComponent, {
    duration: 5000,
    data: config as SnackbarConfig,
  });
};

MatSnackBar.prototype.showSuccess = function(message) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(ShellSnackbarComponent, {
    duration: 5000,
    data: {
      status: 'success',
      message,
    } as SnackbarConfig,
  });
};

MatSnackBar.prototype.showSuccessWithLink = function(message, navTo) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(ShellSnackbarComponent, {
    duration: 5000,
    data: {
      status: 'success',
      message,
      navTo,
    } as SnackbarConfig,
  });
};

MatSnackBar.prototype.showError = function(message) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(ShellSnackbarComponent, {
    duration: 5000,
    data: {
      status: 'error',
      message
    } as SnackbarConfig,
  });
};

MatSnackBar.prototype.showErrorUnexpected = function(message) {
  const snackbar: MatSnackBar = this;
  return snackbar.openFromComponent(ShellSnackbarComponent, {
    duration: 5000,
    data: {
      status: 'errorUnexpected',
      message,
    } as SnackbarConfig,
  });
};

declare module '@angular/material/snack-bar/snack-bar' {
  interface MatSnackBar {
    show(config: {
      status: 'success' | 'error' | 'error unexpected';
      message: string;
      navTo?: string;
    }): MatSnackBarRef<ShellSnackbarComponent>;
    showSuccess(message: string): MatSnackBarRef<ShellSnackbarComponent>;
    showSuccessWithLink(message: string, navTo: string): MatSnackBarRef<ShellSnackbarComponent>;
    showError(message: string): MatSnackBarRef<ShellSnackbarComponent>;
    showErrorUnexpected(message: string): MatSnackBarRef<ShellSnackbarComponent>;
  }
}
