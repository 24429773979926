import { AbstractControlOptions, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

type ControlStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

export declare interface TypedAbstractControlOptions<T> extends AbstractControlOptions {
  validators?: TypedValidatorFn<T> | TypedValidatorFn<T>[] | null;
  asyncValidators?: TypedAsyncValidatorFn<T> | TypedAsyncValidatorFn<T>[] | null;
}

export class TypedFormGroup<T extends { [key: string]: any }> extends FormGroup {
  controls: T;
  value: { [key in keyof T]: T[key]['value'] };
  valueChanges!: Observable<{ [key in keyof T]: T[key]['value'] }>;
  statusChanges!: Observable<ControlStatus>;
  errors!: { message: string };
  constructor(
    controls: T,
    validatorOrOpts?: TypedValidatorFn<T> | TypedValidatorFn<T>[] | AbstractControlOptions | null,
    asyncValidator?: TypedAsyncValidatorFn<T> | TypedAsyncValidatorFn<T>[] | null) {
    super(controls, validatorOrOpts as any, asyncValidator as any);
  }
  patchValue(
    value: { [key in keyof T]?: T[key]['value'] },
    options?: { onlySelf?: boolean; emitEvent?: boolean },
  ) {
    super.patchValue(value, options);
  }
  setValue(
    value: { [key in keyof T]: T[key]['value']; },
    options?: { onlySelf?: boolean; emitEvent?: boolean },
  ) {
    super.patchValue(value, options);
  }
  reset(value?: { [key in keyof T]?: T[key]['value'] }, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    super.reset(value, options);
  }
  addControl<K extends keyof T>(name: K, control: TypedFormControl<K>) {
    super.addControl(name as string, control);
  }
  removeControl<K extends keyof T>(name: K) {
    super.removeControl(name as string);
  }
  registerControl<K extends keyof T>(name: K, control: TypedFormControl<K>) {
    return super.registerControl(name as string, control);
  }
  setControl<K extends keyof T>(name: K, control: TypedFormControl<K>) {
    super.setControl(name as string, control);
  }
  contains(controlName: keyof T) {
    return super.contains(controlName as string);
  }
  setValidators(v: TypedValidatorFn<T> | TypedValidatorFn<T>[] | null) {
    super.setValidators(v as any);
  }
  setAsyncValidators(v: TypedAsyncValidatorFn<T> | TypedAsyncValidatorFn<T>[] | null) {
    super.setAsyncValidators(v as any);
  }
}

export declare type TypedValidatorFn<T> = (control: TypedFormControl<T>) =>
  ValidationErrors | null;

export declare type TypedAsyncValidatorFn<T> = (control: TypedFormControl<T>) =>
  Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;

export class TypedFormControl<T> extends FormControl {
  value!: T;
  valueChanges!: Observable<{ [key in keyof T]: T[key] }>;
  statusChanges!: Observable<ControlStatus>;
  errors!: { message: string } | null;
  constructor(
    formState?: T,
    validatorOrOpts?: TypedValidatorFn<T> | TypedValidatorFn<T>[] | AbstractControlOptions | null,
    asyncValidator?: TypedAsyncValidatorFn<T> | TypedAsyncValidatorFn<T>[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }
  patchValue(value: T, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }) {
    super.patchValue(value, options);
  }
  setValue(value: T, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }) {
    super.setValue(value, options);
  }
  reset(value?: T, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    super.reset(value, options);
  }
}

export class TypedFormArray<T> extends FormArray {
  controls: TypedFormControl<T>[];
  value: T[];
  constructor(
    controls: TypedFormControl<T>[],
    validatorOrOpts?: TypedValidatorFn<T> | TypedValidatorFn<T>[] | AbstractControlOptions | null,
    asyncValidator?: TypedAsyncValidatorFn<T> | TypedAsyncValidatorFn<T>[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }
  at(index: number) {
    return super.at(index) as TypedFormControl<T>;
  }
  push(control: TypedFormControl<T>) {
    super.push(control);
  }
  insert(index: number, control: TypedFormControl<T>) {
    super.insert(index, control);
  }
  setControl(index: number, control: TypedFormControl<T>) {
    super.setControl(index, control);
  }
  setValue(value: T[], options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    super.setValue(value, options);
  }
  patchValue(value: T[], options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    super.patchValue(value, options);
  }
}

FormBuilder.prototype.groupTyped = FormBuilder.prototype.group as any;
FormBuilder.prototype.arrayTyped = FormBuilder.prototype.array as any;

type ControlsConfigValidators<T> = T extends infer U
  ? (U extends TypedFormArray<infer B>
    ? ((control: TypedFormArray<B>) => ValidationErrors | null)[] : TypedValidatorFn<U>[]) : never;

type ControlsConfig<T> = {
  [key in keyof T]: [T[key]] | [T[key], ControlsConfigValidators<T[key]>]
};

type MapControlsConfigToControls<T> = {
  [key in keyof T]: T[key] extends Array<any>
  ? (T[key][0] extends TypedFormArray<infer Y>
    ? TypedFormArray<Y> : TypedFormControl<T[key][0]>) : never;
};

export type TypedForm<T> = TypedFormGroup<MapControlsConfigToControls<T>>;

declare module '@angular/forms' {
  interface FormBuilder {

    /**
     * Functionally identical to `formbuilder.group()` but with stronger types
     * providing better autocompletion support in Typescript as well as templates.
     * Note: the most upvoted issue on Angular concerns this problem.
     * https://github.com/angular/angular/issues/13721
     */
    groupTyped: <T>(
      controlsConfig: ControlsConfig<T>,
      options?: TypedAbstractControlOptions<T> | null,
    ) => TypedForm<typeof controlsConfig>;

    arrayTyped: <T>(
      controlsConfig: TypedFormControl<T>[],
      validatorOrOpts?: TypedValidatorFn<T> | TypedValidatorFn<T>[] | TypedAbstractControlOptions<T> | null,
      asyncValidator?: TypedAsyncValidatorFn<T> | TypedAsyncValidatorFn<T>[] | null,
    ) => TypedFormArray<T>;
  }

}

// example including a formarray
//
// const fg = this.formBuilder.groupTyped({
//   str: ['', [rules.minLength(2)]],
//   arr: [[''], []],
//   formArray: [this.formBuilder.arrayTyped(new Array<string>()), [rules.minFormArrayLength(3)]]
// });

