import { ActivatedRouteSnapshot } from '@angular/router';

ActivatedRouteSnapshot.prototype.getParam = function(name: PossibleRouteParams) {
  const snapshot: ActivatedRouteSnapshot = this;
  return getRouteParam(snapshot, name);
};

const getRouteParam = (snapshot: ActivatedRouteSnapshot, param: PossibleRouteParams) => getRouteParams(snapshot)[param];

const getRouteParams = (snapshot: ActivatedRouteSnapshot) => {
  // Walk up the route tree
  while (snapshot.parent) {
    snapshot = snapshot.parent;
  }
  // Walk down the route tree and accumulate a map of route params
  const result = {} as {[key in PossibleRouteParams]: string};
  while (!!snapshot.firstChild) {
    Object.assign(result, snapshot.firstChild.params);
    snapshot = snapshot.firstChild;
  }
  return result;
};

type PossibleRouteParams
  = 'serviceBlockId'
  | 'organizationid'
  | 'scheduleId'
  | 'jobId'
  | 'vehicleId'
  | 'projectId'
  | 'formId'
  | 'userAccountId'
  | 'jobType'
  ;

declare module '@angular/router' {
  interface ActivatedRouteSnapshot {
    /**
     * Allows you to synchronously get the current route parameter value for the provided parameter name
     */
    getParam(paramName: PossibleRouteParams): string;
  }
}
