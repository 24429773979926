import { ActivatedRoute, ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';

Router.prototype.observeRouteParam = function(param: PossibleRouteParams) {
  const router: Router = this;
  return new (class CustomSubject extends Observable<string> {
    constructor() {
      super(observer => {
        observer.next(getRouteParam(router.routerState.snapshot.root, param));
        const routerSubscription = router.events.pipe(
          filter(e => e instanceof NavigationEnd),
          map(() => getRouteParam(router.routerState.snapshot.root, param)),
          distinctUntilChanged(),
          tap(paramValue => observer.next(paramValue)),
        ).subscribe();
        return () => routerSubscription.unsubscribe();
      });
    }
  })();
};

Router.prototype.observeNavigationEnd = function() {
  const router: Router = this;
  return new (class CustomSubject extends Observable<string> {
    constructor() {
      super(observer => {
        observer.next(router.url);
        const routerSubscription = router.events.pipe(
          filter(e => e instanceof NavigationEnd),
          distinctUntilChanged(),
          tap(() => observer.next(router.url)),
        ).subscribe();
        return () => routerSubscription.unsubscribe();
      });
    }
  })();
};

Router.prototype.observeIsNavigating = function() {
  const router: Router = this;
  return new (class CustomSubject extends Observable<boolean> {
    constructor() {
      super(observer => {
        observer.next(false);
        const routerSubscription = router.events.pipe(
          distinctUntilChanged(),
          tap(e => {
            if (e instanceof NavigationStart) {
              observer.next(true);
            } else if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
              observer.next(false);
            }
          }),
        ).subscribe();
        return () => routerSubscription.unsubscribe();
      });
    }
  })();
};

Router.prototype.navigateNoHistory = function(activatedRoute: ActivatedRoute, commands: string[]): Promise<any> {
  const router: Router = this;
  router.navigate(commands, { relativeTo: activatedRoute, skipLocationChange: true })
    .then(() => window.history.replaceState(null, null, router.url));
  return router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    take(1),
  ).toPromise();
};

Router.prototype.navigateByUrlNoHistory = function(activatedRoute: ActivatedRoute, url: string): Promise<any> {
  const router: Router = this;
  router.navigateByUrl(url, { /* Removed unsupported properties by Angular migration: relativeTo. */ skipLocationChange: true })
    .then(() => window.history.replaceState(null, null, router.url));
  return router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    take(1),
  ).toPromise();
};

Router.prototype.getParam = function(name: PossibleRouteParams) {
  const router: Router = this;
  return getRouteParam(router.routerState.snapshot.root, name);
};

const getRouteParam = (snapshot: ActivatedRouteSnapshot, param: PossibleRouteParams) => getRouteParams(snapshot)[param];

const getRouteParams = (snapshot: ActivatedRouteSnapshot) => {
  // Walk up the route tree
  while (snapshot.parent) {
    snapshot = snapshot.parent;
  }
  // Walk down the route tree and accumulate a map of route params
  const result = {} as {[key in PossibleRouteParams]: string};
  while (!!snapshot.firstChild) {
    Object.assign(result, snapshot.firstChild.params);
    snapshot = snapshot.firstChild;
  }
  return result;
};

type PossibleRouteParams
  = 'serviceBlockId'
  | 'organizationid'
  | 'vehicleId'
  | 'projectId'
  | 'tagId'
  | 'clientId'
  | 'facilityId'
  | 'profileId'
  | 'formDashboardId'
  | 'scheduleId'
  | 'formId'
  | 'userAccountId'
  | 'reportId'
  | 'id';

declare module '@angular/router' {
  interface Router {
    /**
     * Observes changes to a route once it has completed navigation
     */
    observeNavigationEnd: () => Observable<string>;
    /**
     * Publishes true or false depending on whether or not the router is currently navigating
     */
    observeIsNavigating: () => Observable<boolean>;
    /**
     * Observes changes to the provided route param
     */
    observeRouteParam: (param: PossibleRouteParams) => Observable<string>;
    /**
     * Similar to router.navigate() except that this should be used when navigating within tabs.
     * This function ensures that a navigation will not be pushed onto the route history.
     * This ensures that clicking the back button doesn't navigate the user to the previously selected tab.
     */
    navigateNoHistory: (activatedRoute: ActivatedRoute, commands: string[]) => Promise<Event>;
    /**
     * Similar to router.navigateByUrl() except that this should be used when navigating within tabs.
     * This function ensures that a navigation will not be pushed onto the route history.
     * This ensures that clicking the back button doesn't navigate the user to the previously selected tab.
     */
    navigateByUrlNoHistory: (activatedRoute: ActivatedRoute, url: string) => Promise<Event>;
    /**
     * Allows you to synchronously get the current route parameter value for the provided parameter name
     */
    getParam(paramName: PossibleRouteParams): string;
  }
}
